// Import statements remain the same
import { useEffect, useState } from 'react';
import appConfig from '../../appConfig';
import { useMsal } from "@azure/msal-react";
import { fetchSecure, getToken } from "../../services/apiService";
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import ModalDialog from '../../components/authentication/ModalDialog';

const RequestList = () => {
  const { instance, accounts } = useMsal();
  const [requests, setRequests] = useState([]);
  const [status, setStatus] = useState('All');
  const [statusId, setStatusId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Updated statuses array
  const statuses = [
    { id: null, label: 'All' },
    { id: 1, label: 'Pending' },
    { id: 2, label: 'Processing' },
    { id: 3, label: 'Completed' },
    { id: 4, label: 'Error' }
  ];

  const getRequests = async () => {
    const accessToken = await getToken(instance, accounts);

    const url = statusId !== null
      ? `${appConfig.apiUrl}requests/${statusId}`
      : `${appConfig.apiUrl}requests`;

    const data = await fetchSecure(accessToken, url);
    setRequests(data);
  };

  useEffect(() => {
    getRequests();

    const intervalId = setInterval(() => {
      getRequests();
    }, 60 * 1000);

    return () => clearInterval(intervalId);

  }, [statusId]);

  const formatDate = (dateString) => {
    return dateString
      ? moment(dateString).format('YYYY/MM/DD HH:mm')
      : 'N/A';
  };

  const handleSelect = (eventKey) => {
    const statId = eventKey === 'null' ? null : parseInt(eventKey);
    setStatusId(statId);

    const currentStatus = statuses.find((s) => s.id === statId)?.label || 'All';
    setStatus(currentStatus);
  };

  const showErrorDetails = async (e, requestID) => {
    e.preventDefault();
    const accessToken = await getToken(instance, accounts);
    const url = `${appConfig.apiUrl}requests/error/${requestID}`;
    const data = await fetchSecure(accessToken, url);
    setErrorMessage(data);
    setShowModal(true);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If same column, toggle direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new column, default to ascending
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedRequests = [...requests].sort((a, b) => {
    if (!sortColumn) {
      return 0;
    }

    let aValue = a[sortColumn];
    let bValue = b[sortColumn];

    // For date columns
    if (['queuedDate', 'completedDate', 'lockedDate'].includes(sortColumn)) {
      aValue = aValue ? new Date(aValue) : new Date(0);
      bValue = bValue ? new Date(bValue) : new Date(0);
    } else if (sortColumn === 'statusID') {
      aValue = statuses.find(s => s.id === aValue)?.label || '';
      bValue = statuses.find(s => s.id === bValue)?.label || '';
    } else {
      aValue = aValue?.toString() || '';
      bValue = bValue?.toString() || '';
    }

    if (aValue < bValue) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <>
      <div className="container-fluid width-all-screen">
        <div className="table-responsive text-centered min-h">
          <table className="table table-striped table-bordered table-sm w-auto tiny-font">
            <thead className="thead-dark">
              <tr>
                <th onClick={() => handleSort('requestID')} style={{ cursor: 'pointer' }}>
                  Request ID {sortColumn === 'requestID' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('instance')} style={{ cursor: 'pointer' }}>
                  Instance {sortColumn === 'instance' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('application')} style={{ cursor: 'pointer' }}>
                  Application {sortColumn === 'application' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('headerID')} style={{ cursor: 'pointer' }}>
                  Header ID {sortColumn === 'headerID' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('configurationID')} style={{ cursor: 'pointer' }}>
                  Configuration ID {sortColumn === 'configurationID' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('configurationType')} style={{ cursor: 'pointer' }}>
                  Configuration Type {sortColumn === 'configurationType' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('queuedDate')} style={{ cursor: 'pointer' }}>
                  Queued Date {sortColumn === 'queuedDate' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('completedDate')} style={{ cursor: 'pointer' }}>
                  Completed Date {sortColumn === 'completedDate' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('lockedDate')} style={{ cursor: 'pointer' }}>
                  Locked Date {sortColumn === 'lockedDate' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('lockedBy')} style={{ cursor: 'pointer' }}>
                  Locked By {sortColumn === 'lockedBy' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th>
                  <span onClick={() => handleSort('statusID')} style={{ cursor: 'pointer' }}>
                    Status {sortColumn === 'statusID' && (sortDirection === 'asc' ? '▲' : '▼')}
                  </span>
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle variant="primary btn-sm" id="dropdown-basic">
                      Filter ({status})
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {statuses.map((statusOption) => (
                        <Dropdown.Item
                          key={statusOption.id}
                          eventKey={statusOption.id !== null ? statusOption.id.toString() : 'null'}
                        >
                          {statusOption.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedRequests.map((request) => (
                <tr key={request.requestID}>
                  <td>{request.requestID}</td>
                  <td>{request.instance}</td>
                  <td>{request.application}</td>
                  <td>{request.headerID}</td>
                  <td>{request.configurationID}</td>
                  <td>{request.configurationType}</td>
                  <td>{formatDate(request.queuedDate)}</td>
                  <td>{formatDate(request.completedDate)}</td>
                  <td>{formatDate(request.lockedDate)}</td>
                  <td>{request.lockedBy || ''}</td>
                  <td
                    className={
                      request.statusID === 1 //Pending
                      ? 'text-bg-secondary'
                      : request.statusID === 2 //Processing
                      ? 'text-bg-info'
                      : request.statusID === 3 //Completed
                      ? 'text-bg-success'
                      : 'text-bg-danger'
                    }
                  >
                    {request.statusID === 4
                      ? (
                        <button className='btn btn-danger' onClick={async (e) => { await showErrorDetails(e, request.requestID); }}>
                          {statuses.find((s) => s.id === request.statusID)?.label || ''}
                        </button>
                      )
                      : (statuses.find((s) => s.id === request.statusID)?.label || '')
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ModalDialog
        title="Error detail"
        showModal={showModal}
        handleClose={() => { setShowModal(false); }}
        handleOk={false}
        okLabel="Ok"
        closeLabel="Close"
        isOk={false}>
        <div>
          {errorMessage}
        </div>
      </ModalDialog>
    </>
  );
};

export default RequestList;
